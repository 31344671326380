#global-defs-box-line1-left {
    height: 500px;
}
#global-defs-box-line1-right {
    height: 500px;
}
#product-box-prices {
    height: 560px;
}
#global-defs-box-line2-left {
    height: 500px;
}
#global-defs-box-line2-right {
    height: 500px;
}
#Product-Edit-Caption {
    margin-top: 10px;
}
#product-setup-form {
    margin-left: 10px;
}
#product-form-button {
    margin-left: auto;
    margin-bottom: 10px;
}
#product-services-grid {
    margin-top: 20px;
}
#product-edit-pricing {
    margin-left: 10px;
    margin-top: 20px;
}
#product-service-edit-button {
    margin-left: 10px;
  }
#setting-onboarding-box {
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 10px;
}
#setting-onboard-content-block {
    margin-bottom: 20px;
}
#setting-regions-box {
    margin-right: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-top: 10px;
}
#settings-tou-box {
    margin-right: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-top: 10px;
}
#settings-partner-regions-one-grid {
    margin-right: 40px;
    margin-left: 10px;
    margin-top: 20px;
  }

#global-region-tab-countries {
    margin-top: 10px;
}

#doc-add-dialog {
    margin-right: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-top: 10px;
}
