#service-cost-rates-header-grid-base {
  margin-left: 30px;
  margin-right: 10px;
  height: 210px;
}
#serviceProviderTab {
  margin-left: 125px;
  margin-right: 20px;
}
#service-cost-rates-entries {
  margin-left: 20px;
}
#service-cost-entries-area {
  margin-top: 20px;
}
#service-cost-entries-form-edit-base {
  height: 600px;
}
#cost-rates-edit-tab {
  margin-left: 10px;
  margin-top: 20px;
}
#service-cost-rates-risk-base-grid {
  height: 230px;
}
#service-cost-rates-entries-base-grid {
  height: 300px;
}
#service-fee-edit-form {
  margin-left: 20px;
  margin-top: 20px;
}
#service-cost-rates-entries-base-edit-grid {
  height: 280px;
}
#product-service-cost-rates-entries-base-edit-grid {
  height: 280px;
}
#service-edit-btn-section-1 {
  margin-right: 10px;
  margin-bottom: 10px;
}
#service-fee-header-edit-form {
  margin-top: 10px;
}
#service-fee-box1 {
  margin-top: 10px;
  margin-left: 10px;
}
#service-fee-box2 {
  margin-top: 20px;
  margin-left: 10px;
}
#service-fee-box3 {
  margin-top: 30px;
  margin-left: 10px;
  margin-bottom: 10px;
}
#risk-level-copy-form {
  margin-left: 10px;
  margin-top: 10px;
}
#product-edit-support-buttons {
  margin-top: 10px;
  margin-left: 10px;
}
#product-service-edit-btn-section-1 {
  margin-bottom: 30px;
}
#product-service-edit-btn-section-2 {
  margin-bottom: 180px;
}
#product-service-edit-btn-section-3 {
  margin-bottom: 0px;
}
#product-service-edit-btn-margin-1 {
  margin-bottom: 60px;
}
