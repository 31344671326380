#inq-title {
  margin: 10px;
}
#ing-req-title {
  margin-bottom: 20px;
  margin-left: 8px;
  margin-top: 15px;
}
#inq-req-form {
  margin: 10px;
}
#inq-chat-box {
  margin: 10px;
}
#inquiry_doc_btn {
  margin-right: 10px;
}
#inquiry-note-section {
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 10px;
}
#inquiry-note-text-section {
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 10px;
}
#inquiry-doc-req-section {
  margin-left: 10px;
  margin-bottom: 30px;
}
#inquiry-req-doc-grid {
  width: 1000px;
  margin-top: 10px;
}
#inquiry-doc-req-rmv-btn {
  margin-top: 10px;
}
#inquiry-msg-section {
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 15px;
}
#accept-reject-pop {
  margin-left: 10px;
  margin-top: 20px;
}
#inquiry-bottom-line {
  margin-bottom: 5px;
}
#inq-close-button {
  margin-left: auto;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
