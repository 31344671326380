#partnerGrid {
  margin-left: 10px;
  margin-right: 10px;
  height: 200px;
}
#serviceProviderTab {
  margin-left: 125px;
  margin-right: 20px;
}
#partner-edit-address-form-edit {
  margin-right: 20px;
  margin-left: 30px;
  margin-top: 10px;
}
#hm-btn-section-3 {
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 30px;
}
#rouing-system-services-Grid-2 {
  width: 300px;
}
#rouing-system-services-feature-Grid {
  width: 700px;
}
#partner-editPage-button {
  margin-left: auto;
  margin-right: 10px;
}
#partner-customer-product-edit {
  margin-right: 50px;
  margin-left: 22px;
}
#partner-customer-services-edit-box {
  margin-top: 30px;
}
#client-product-services-edit-grid {
  height: 280px;
  width: 500px;
}
#partner-product-services-pricing-grid {
  margin-left: 20px;
  height: 300px;
}

#partner-cost-entries-form-edit-base {
  margin-right: 20px;
  margin-left: 5px;
  height: 600px;
}
#client-tou-doc-Grid {
  height: 330px;
}
#client-product {
  margin-top: 10px;
}
#client-product-tab {
  margin-bottom: 10px;
}
#client-product-form {
  margin-top: 0px;
}
#partner-products-edit-Grid {
  margin-left: 10px;
  margin-bottom: 20px;
}
#productedit-pop-form-area {
  margin-right: 20px;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}
#info-dialog {
  margin-top: 30px;
  margin-left: 40px;
}
