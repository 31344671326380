#base-box {
  height: 930px;
}
#client-close-button {
  margin-left: auto;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
#header-box {
  margin-left: 10px;
  margin-top: 10px;
}
