#serviceProviderTab {
  margin-left: 125px;
  margin-right: 20px;
}
#service-SystemServiceFeaturesGrid {
  margin-top: 10px;
  width: 95%;
  height: 300px;
}
#services-edit-button {
  margin-top: 20px;
  margin-right: 0;
  margin-bottom: 20px;
  margin-left: auto;
}
#services-edit_close-button {
  margin-right: 10px;
}
#service-costrates {
  margin-top: 10px;
}
#service-serviceoverview-grid {
  margin-right: 10px;
  margin-bottom: 20px;
  margin-left: 10px;
}
#service-serviceprovider-data {
  margin-top: 10px;
}
#service-SystemServiceAccountsGrid {
  margin-top: 10px;
  height: 140px;
}
#merch-serv-tab {
  margin-left: 80px;
}
