@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

@import 'styles/documents_echange';
@import 'styles/mailing';
@import 'styles/internal-messaging';

* {
  box-sizing: border-box;
}

:root {
  --app-bg: #fff;
  --app-font-color: #000;
  --app-content-secondary-color: #1f1c2e;
  --app-header-color: #f7f7f7;
  --table-border: #6a6d75;
  --table-header: rgba(195, 207, 244, 0.5);
  --action-color: #2869ff;
  --action-color-hover: #6291fd;
  --filter-reset: #2c394f;
  --filter-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  --note-merchant-bg-color: #455870;
  --note-personally-bg-color: #2a9d8f;
  --note-account-bg-color: #f4a261;
  --note-transaction-bg-color: #e76f51;
  --note-client-bg-color: #455870;
  --note-financialNetwork-bg-color: #455870;
  --note-serviceProvider-bg-color: #455870;
  --note-service-bg-color: #f4a261;
}

$font-xs: 12px;
$font-small: 14px;
$font-medium: 16px;
$font-large: 24px;

$indent0: 0;
$indent1: 0.5rem;
$indent2: 0.75rem;
$indent3: 1rem;
$indent4: 1.25rem;

.dx-field-item-label-text {
  color: black !important;
}

.row-flex-space-between {
  justify-content: space-between;
}

.link-btn {
  color: rgb(3, 169, 244);
  text-decoration: underline;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}

.wide-form {
  width: 550px !important;
}

.p-0 {
  padding: $indent0 !important;
}
.p-1 {
  padding: $indent1 !important;
}
.p-2 {
  padding: $indent2 !important;
}
.p-3 {
  padding: $indent3 !important;
}
.p-4 {
  padding: $indent4 !important;
}

.pl-1 {
  padding-left: $indent1 !important;
}
.pl-2 {
  padding-left: $indent2 !important;
}
.pl-3 {
  padding-left: $indent3 !important;
}
.pl-4 {
  padding-left: $indent4 !important;
}

.pr-1 {
  padding-right: $indent1 !important;
}
.pr-2 {
  padding-right: $indent2 !important;
}
.pr-3 {
  padding-right: $indent3 !important;
}
.pr-4 {
  padding-right: $indent4 !important;
}

.m-0 {
  margin: 0 !important;
}
.m-1 {
  margin: $indent1 !important;
}
.m-2 {
  margin: $indent2 !important;
}
.m-3 {
  margin: $indent3 !important;
}
.m-4 {
  margin: $indent4 !important;
}

.ml-0 {
  margin-left: $indent0 !important;
}
.ml-1 {
  margin-left: $indent1 !important;
}
.ml-2 {
  margin-left: $indent2 !important;
}
.ml-3 {
  margin-left: $indent3 !important;
}
.ml-4 {
  margin-left: $indent4 !important;
}

.mr-0 {
  margin-right: $indent0 !important;
}
.mr-1 {
  margin-right: $indent1 !important;
}
.mr-2 {
  margin-right: $indent2 !important;
}
.mr-3 {
  margin-right: $indent3 !important;
}
.mr-4 {
  margin-right: $indent4 !important;
}

.mb-0 {
  margin-bottom: $indent0 !important;
}
.mb-1 {
  margin-bottom: $indent1 !important;
}
.mb-2 {
  margin-bottom: $indent2 !important;
}
.mb-3 {
  margin-bottom: $indent3 !important;
}
.mb-4 {
  margin-bottom: $indent4 !important;
}

.mt-0 {
  margin-top: $indent0 !important;
}
.mt-1 {
  margin-top: $indent1 !important;
}
.mt-2 {
  margin-top: $indent2 !important;
}
.mt-3 {
  margin-top: $indent3 !important;
}
.mt-4 {
  margin-top: $indent4 !important;
}

.h-35 {
  height: 35vh;
}

.h-40 {
  height: 40vh;
}

.h-45 {
  height: 45vh;
}

.h-50 {
  height: 50vh;
}

#grid-serviceReqDoc {
  margin-right: 23px;
  margin-left: 11px;
}

#btn-addNewDoc {
  margin-right: 23px;
  height: 35px;
}

#btn-newDocReq {
  height: 35px;
  margin-right: 15px;
}

#btn-doc {
  height: 35px;
}

.grid-approvals {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.note-details-approvals {
  margin-top: 10px;
}

.app-container {
  font-family: 'Poppins', sans-serif;
  background-color: var(--app-bg);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  width: 500px;
}

.app-content {
  padding: 0;
  background-color: var(--app-bg);
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid #e7e7e7;
  margin: 10px;
  overflow: hidden;
  max-height: 80vh;

  &-header {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 4px;
    background-color: var(--app-header-color);
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 452px;
    height: 44px;
    border: 1px solid #e1e1e1;
  }

  &-headerText {
    color: var(--app-font-color);
    font-size: $font-large;
    line-height: 32px;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    font-weight: 900;
  }

  &-headerButton {
    background-color: var(--action-color);
    color: #fff;
    font-size: $font-small;
    line-height: 24px;
    border: none;
    border-radius: 4px;
    height: 32px;
    padding: 0 16px;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      background-color: var(--action-color-hover);
    }
  }

  &-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 4px;

    &-wrapper {
      display: flex;
      align-items: center;
      margin-left: auto;
    }

    @media screen and (max-width: 520px) {
      & {
        flex-direction: column;

        .app-content-actions-wrapper {
          padding-bottom: 16px;
          order: 1;
        }
      }
    }
  }
}

.action-button {
  border-radius: 4px;
  height: 32px;
  background-color: var(--table-header);
  border: 1px solid var(--app-content-secondary-color);
  display: flex;
  align-items: center;
  color: var(--app-content-secondary-color);
  font-size: $font-small;
  margin-left: 8px;
  cursor: pointer;

  span {
    margin-right: 4px;
  }

  &:hover {
    border-color: var(--action-color-hover);
  }

  &:focus,
  &.active {
    outline: none;
    color: var(--action-color);
    border-color: var(--action-color);
  }
}

.note-area-wrapper::-webkit-scrollbar {
  display: none;
}

.note-area-wrapper {
  width: 100%;
  height: 100%;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  padding: 0 4px;
}

.tableView {
  .note-header {
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: var(--table-header);
    // position: sticky;
    top: 0;
    font-weight: bold;
  }

  .note-row {
    display: flex;
    align-items: center;
    border-radius: 4px;
    margin: 5px 0 5px 0;

    &:hover {
      box-shadow: var(--filter-shadow);
      background-color: var(--table-header);
      cursor: pointer;
    }

    .cell-more-button {
      display: none;
    }
  }

  .note-cell {
    flex: 1;
    padding: 4px 16px;
    color: var(--app-content-secondary-color);
    font-size: $font-xs;
    display: flex;
    align-items: center;
  }

  .cell-label {
    display: none;
  }
}

.status {
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  font-size: 12px;

  &:before {
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin-right: 4px;
  }

  &.Merchant\/Partner {
    color: #000000;
    background-color: rgba(43, 169, 114, 0.2);

    &:before {
      background-color: #000000;
    }
  }

  &.Other {
    color: #000000;
    background-color: rgba(43, 169, 114, 0.2);

    &:before {
      background-color: #000000;
    }
  }

  &.Service\/Provider {
    color: #000000;
    background-color: rgba(6, 36, 23, 0.2);

    &:before {
      background-color: #000000;
    }
  }

  &.Service {
    color: #000000;
    background-color: rgba(43, 169, 114, 0.2);

    &:before {
      background-color: #000000;
    }
  }

  &.Transaction {
    color: #000000;
    background-color: rgba(231, 111, 81, 0.575);

    &:before {
      background-color: #000000;
    }
  }

  &.Customer {
    color: #000000;
    background-color: rgba(238, 255, 0, 0.603);

    &:before {
      background-color: #000000;
    }
  }

  &.disabled {
    color: #59719d;
    background-color: rgba(89, 113, 157, 0.2);

    &:before {
      background-color: #59719d;
    }
  }
}

.gridView {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;

  @media screen and (max-width: 520px) {
    & {
      margin: 0;
    }
  }

  .note-header {
    display: none;
  }

  .note-row {
    margin: 8px;
    width: calc(25% - 16px);
    background-color: var(--app-content-secondary-color);
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 0.2s;
    position: relative;

    &:hover {
      transform: scale(1.01);
      box-shadow: var(--filter-shadow);

      .cell-more-button {
        display: flex;
      }
    }

    @media screen and (max-width: 1024px) {
      & {
        width: calc(33.3% - 16px);
      }
    }

    @media screen and (max-width: 820px) {
      & {
        width: calc(50% - 16px);
      }
    }

    @media screen and (max-width: 520px) {
      & {
        width: 100%;
        margin: 8px 0;

        &:hover {
          transform: none;
        }
      }
    }

    .cell-more-button {
      border: none;
      padding: 0;
      border-radius: 4px;
      position: absolute;
      top: 16px;
      right: 16px;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      background-color: var(--app-content-secondary-color);
      color: #fff;
      cursor: pointer;
      display: none;
    }
  }

  .note-cell {
    color: var(--app-content-secondary-color);
    font-size: $font-small;
    margin-bottom: 8px;
  }

  .cell-label {
    opacity: 0.6;
  }
}

.select-note-wrapper {
  margin: 0 10px 0 10px;
  width: 452px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

.note-details-container {
  margin-left: auto;
  margin-right: auto;
  width: 420px;

  &-small {
    margin-top: 20px;
    width: 500px;
  }
}

.note-details-wrapper {
  font-family: 'Poppins', sans-serif;
  border-radius: 1px;
  margin-bottom: 10px;
  border-radius: 15px 0 0 15px;
}

.note-details {
  &-merchant {
    display: flex;
    width: 100%;

    &-colorcode {
      width: 2%;
      // background-color: var(--note-merchant-bg-color);
      border-radius: 15px 0 0 15px;
    }

    &-infoText {
      width: 55%;
      margin-left: 10px;
    }

    &-outputText {
      width: 49%;
      margin-right: 10px;
      margin-left: auto;
      font-weight: bold;
    }
  }

  &-personally {
    display: flex;
    width: 100%;

    &-colorcode {
      width: 100%;
      // background-color: var(--note-personally-bg-color);
      border-radius: 15px 0 0 15px;
    }

    &-none {
      width: 100%;
    }

    &-block {
      margin-left: 10px;
      display: flex;
      background-color: #fff;

      &-infoText {
        width: 30%;
        margin-left: 10px;
      }

      &-outputText {
        width: 45%;
        margin-right: 10px;
        margin-left: auto;
        font-weight: 500;

        &-verified {
          width: 20%;
        }
      }
    }
  }

  &-account {
    display: flex;
    width: 100%;

    &-colorcode {
      width: 100%;
      // background-color: var(--note-account-bg-color);
      border-radius: 15px 0 0 15px;
    }

    &-block {
      margin-left: 10px;
      display: flex;
      background-color: #fff;

      &-infoText {
        width: 45%;
        margin-left: 10px;
      }

      &-outputText {
        width: 45%;
        margin-right: 10px;
        margin-left: auto;
        font-weight: 500;
      }
    }
  }

  &-transaction {
    display: flex;
    width: 100%;

    &-colorcode {
      width: 2%;
      background-color: var(--note-transaction-bg-color);
      border-radius: 15px 0 0 15px;
    }

    &-infoText {
      width: 49%;
      margin-left: 10px;
    }

    &-outputText {
      width: 49%;
      margin-right: 10px;
      margin-left: auto;
      font-weight: bold;
    }
  }

  &-client {
    display: flex;
    width: 100%;

    &-colorcode {
      width: 2%;
      // background-color: var(--note-client-bg-color);
      border-radius: 15px 0 0 15px;
    }

    &-infoText {
      width: 55%;
      margin-left: 10px;
    }

    &-outputText {
      width: 49%;
      margin-right: 10px;
      margin-left: auto;
      font-weight: bold;
    }
  }

  &-financialNetwork {
    display: flex;
    width: 100%;

    &-colorcode {
      width: 2%;
      background-color: var(--note-financialNetwork-bg-color);
      border-radius: 15px 0 0 15px;
    }

    &-infoText {
      width: 49%;
      margin-left: 10px;
    }

    &-outputText {
      width: 49%;
      margin-right: 10px;
      margin-left: auto;
      font-weight: bold;
    }
  }

  &-serviceProvider {
    display: flex;
    width: 100%;

    &-colorcode {
      width: 2%;
      background-color: var(--note-serviceProvider-bg-color);
      border-radius: 15px 0 0 15px;
    }

    &-infoText {
      width: 49%;
      margin-left: 10px;
    }

    &-outputText {
      width: 49%;
      margin-right: 10px;
      margin-left: auto;
      font-weight: bold;
    }
  }

  &-service {
    display: flex;
    width: 100%;

    &-colorcode {
      width: 100%;
      background-color: var(--note-service-bg-color);
      border-radius: 15px 0 0 15px;
    }

    &-block {
      margin-left: 10px;
      display: flex;
      background-color: #fff;

      &-infoText {
        width: 45%;
        margin-left: 10px;
      }

      &-outputText {
        width: 45%;
        margin-right: 10px;
        margin-left: auto;
        font-weight: 500;
      }
    }
  }
}

.note-content {
  font-family: 'Poppins', sans-serif;
  padding: 10px;
}

#noteContainer {
  width: 452px;
  margin-left: auto;
  margin-right: auto;
  height: 275px;
}

#notificationContainer {
  margin-left: 11px;
  margin-right: 12px;
}
