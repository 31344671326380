#crm-activity-line {
  height: 50px;
  background-color: lightgoldenrodyellow;
}
#crm-activity-box {
  margin-right: 20px;
  margin-bottom: 15px;
  margin-left: 20px;
  margin-top: 10px;
}
#signup-continue-box {
  height: 70px;
}
#cont-signup-label {
  margin-top: 5px;
}
#inquiry-bottom-line {
  margin-bottom: 5px;
}
#client-close-button {
  margin-left: auto;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
#client-base-box {
  height: 400px;
}
#client-data-name {
  margin-left: 10px;
}
#client-base-box-right-2 {
  height: 930px;
}
#client-access-box {
  margin-right: 20px;
  margin-bottom: 15px;
  margin-left: 20px;
  margin-top: 10px;
  height: 440px;
}
#client-users-box {
  margin-right: 20px;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-top: 10px;
  height: 440px;
}
#risk-matrix-result-line {
  margin-top: 20px;
}
#client-users-transactions-box {
  margin-left: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  height: 600px;
}
#Client-notes-box {
  margin: 10px;
}
#client-comms-notes-grid {
  margin-left: 10px;
  margin-top: 10px;
  margin-right: 10px;
}
#client-comms-inquirydata-grid {
  margin-right: 20px;
  margin-top: 20px;
}
#client-comms-box {
  height: 815px;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-top: 10px;
}
#client-reg-prod-box {
  height: 440px;
  margin-right: 20px;
  margin-bottom: 20x;
  margin-left: 20px;
  margin-top: 10px;
}
#client-comms-documents-grid {
  margin-top: 20px;
}
#client-ForexTrades-box {
  margin-left: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  height: 800px;
}
#client-kyc-box {
  margin-right: 20px;
  margin-bottom: 15px;
  margin-left: 20px;
  margin-top: 10px;
  height: 340px;
}
#share-doc-pop-frm {
  margin: 10px;
}
#share-doc-pop-lbl {
  margin-bottom: 10px;
}
#share-doc-pop-btn {
  margin-left: auto;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 20px;
}