#product-box-left {
    height: 440px;
}
#product-box-right {
    height: 440px;
}
#product-box-prices {
    height: 560px;
}
#Product-Edit-Caption {
    margin-top: 10px;
}
#product-setup-form {
    margin-left: 10px;
}
#product-form-button {
    margin-left: auto;
    margin-bottom: 10px;
}
#product-services-grid {
    margin-top: 20px;
}
#product-edit-pricing-box {
    margin-left: 20px;
    margin-top: 10px;
}
#product-service-edit-button {
    margin-left: 10px;
  }
#partner-product-edit {
    margin-right: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-top: 10px;
}
#product-box-sell-rates {
    height: 600px;
}