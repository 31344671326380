#servproviderGrid {
  margin-left: 10px;
  margin-right: 10px;
  height: 340px;
}
#serviceProviderTab {
  margin-left: 125px;
  margin-right: 20px;
}
#form-edit {
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 10px;
}
#serv-prop-button {
  margin-top: 20px;
  margin-right: 0;
  margin-bottom: 20px;
  margin-left: auto;
}
#serv-close-button {
  margin-right: 10px;
}
#serviceprovider-services-tab-grid {
  margin-top: 10px;
  width: 1000px;
  height: 350px;
}
#service-prov-clients-signup-tab-grid {
  margin-top: 10px;
  height: 350px;
}
#serviceprovider-notes-grid {
  margin-right: 10px;
  margin-top: 10px;
  height: 350px;
}
#pt-prov-grid {
  height: 200px;
  margin-left: 20px;
}
