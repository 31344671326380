#partner-services-provider-box {
    height: 440px;
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 10px;
}
#partner-services-feature-box {
    height: 440px;
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 10px;
}
#partner-services-box-prices {
    height: 640px;
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 10px;
}
#Product-Edit-Caption {
    margin-top: 10px;
}
#product-setup-form {
    margin-left: 10px;
}
#product-form-button {
    margin-left: auto;
    margin-bottom: 10px;
}
#product-services-grid {
    margin-top: 20px;
}
#product-edit-pricing {
    margin-left: 10px;
    margin-top: 20px;
}
#product-service-edit-button {
    margin-left: 10px;
  }
#partner-service-provider-edit {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
  }
#partner-customer-documents-box {
    margin-right: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-top: 10px;
}